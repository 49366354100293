import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthService from "../../../../services/vo/auth-service";
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

const PasswordChange = ({ tokenData, onPasswordChange, type, isLoginFlow }) => {
  const { handleSubmit, register, errors, getValues } = useForm();
  const [globalVariable] = useGlobalStore(false);
  const [error, SetError] = useState(null);
  const [incorrectPasswordError, SetIncorrectPasswordError] = useState(null);

  const onSubmit = async (values) => {
    SetError(null);
    SetIncorrectPasswordError(null);
    try {
      if (isLoginFlow) {
        const data = {
          newPassword: values.new_password,
        };
        const accessToken = await AuthService.getAccessToken();
        var response = await AuthService.UpdateNewPassword(data, accessToken);
        if (response.status) {
          onPasswordChange(true);
        }
      } else {
        const data = {
          currentPassword: values.current_password,
          newPassword: values.new_password,
        };
        var response = await AuthService.ChangePassword(data);
        if (response.data.status == true) {
          onPasswordChange(true);
        } else if (response.data.status == false) {
          SetIncorrectPasswordError(
            globalVariable.moduleData.change_password_incorrect_password_error
          );
        } else {
          SetError(globalVariable.moduleData.change_password_error);
        }
      }
    } catch (err) {
      SetError(globalVariable.moduleData.change_password_error);
      console.log(err);
    }
  };

  return (
    <section className="pass-change">
      <div>
        <div className="main-page-title">
          <h5>{globalVariable.moduleData.change_password_title}</h5>
        </div>
        <div class="flex mob-block wrap">
          <div class="mt-10 width-50">
            <form onSubmit={handleSubmit(onSubmit)}>
              {!isLoginFlow ? (
                <div class="flex column mb-30">
                  <label class="input-label mb-5">
                    {globalVariable.moduleData.change_password_current_passowrd}
                  </label>
                  <input
                    class="input-style-2"
                    type="password"
                    name="current_password"
                    ref={register({
                      required:
                        globalVariable.moduleData
                          .change_password_error_required,
                    })}
                  />
                  <div className="error">
                    {errors.current_password && errors.current_password.message}
                  </div>
                  {incorrectPasswordError ? (
                    <div className="error">{incorrectPasswordError}</div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div class="flex column mb-30">
                <label class="input-label mb-5">
                  {globalVariable.moduleData.change_password_new_passowrd}
                </label>
                <input
                  class="input-style-2"
                  type="password"
                  name="new_password"
                  ref={register({
                    required:
                      globalVariable.moduleData.change_password_error_required,
                  })}
                />
                <div className="error">
                  {errors.new_password && errors.new_password.message}
                </div>
              </div>
              <div class="flex column mb-30">
                <label class="input-label mb-5">
                  {globalVariable.moduleData.change_password_confirm_passowrd}
                </label>
                <input
                  class="input-style-2"
                  name="confirmpassword"
                  type="password"
                  ref={register({
                    required:
                      globalVariable.moduleData.change_password_error_required,
                    validate: (value) =>
                      value === getValues("new_password") ||
                      globalVariable.moduleData.change_password_error_not_match,
                  })}
                />
                <div className="error">
                  {errors.confirmpassword && errors.confirmpassword.message}
                </div>
              </div>
              {error ? <div className="error">{error}</div> : ""}
              <div class="flex mb-30 v-center flex-end">
                {type === "profile" ? (
                  <a
                    className="btn btn-back mr-20"
                    style={{ paddingTop: 7, paddingBottom: 7 }}
                    onClick={onPasswordChange}
                  >
                    {globalVariable.moduleData.change_password_button_back}
                  </a>
                ) : (
                  ""
                )}
                <button className="btn btn-primary" type="submit">
                  {globalVariable.moduleData.change_password_button_update}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordChange;
